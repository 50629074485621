import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// MUI
import { useTheme } from '@mui/styles';
import { Container, Grid, Button, Card, Typography, Badge } from '@mui/material';

// MUI ICONS
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// ASSETS

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import CartContext from 'contexts/CartContext';
import useAuth from 'hooks/useAuth';

const menuStyles = {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '5px 10px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    marginTop: '15px'
};

// ===================== || WEB NAVBAR || ======================
const WebNavbar = ({ handleSearchBar }) => {
    const theme = useTheme();
    const { cartItems } = useContext(CartContext);
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const pageNavigation = (url) => {
        navigate(url, { state: { previousUrl: url } });
    };

    return (
        <Container style={menuStyles}>
            <Grid item container className="webmenu" justifyContent="space-around" alignItems="center" spacing={2}>
                <Grid item textAlign="center" component={Link} to="/">
                    <AnimateButton>
                        <Button sx={{ boxShadow: 'none', minWidth: '90px' }}>
                            <Card sx={{ background: 'none' }}>
                                <HomeOutlinedIcon fontSize="large" color="primary" />
                                <Typography component="div" variant="body1" fontSize="15px" color={theme.palette.dark.main}>
                                    Inicio
                                </Typography>
                            </Card>
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item textAlign="center" component={Link} to="/explore">
                    <AnimateButton>
                        <Button sx={{ boxShadow: 'none', minWidth: '90px' }}>
                            <Card sx={{ background: 'none' }}>
                                <SearchOutlinedIcon fontSize="large" color="primary" />
                                <Typography component="div" variant="body1" fontSize="15px" color={theme.palette.dark.main}>
                                    Explorar
                                </Typography>
                            </Card>
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item textAlign="center" component={Link} to="/categories">
                    <AnimateButton>
                        <Button sx={{ boxShadow: 'none', minWidth: '90px' }}>
                            <Card sx={{ background: 'none' }}>
                                <FormatListBulletedOutlinedIcon fontSize="large" color="primary" />
                                <Typography component="div" variant="body1" fontSize="15px" color={theme.palette.dark.main}>
                                    Categorías
                                </Typography>
                            </Card>
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid
                    item
                    textAlign="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        pageNavigation('/wish-list');
                    }}
                >
                    <AnimateButton>
                        <Button sx={{ boxShadow: 'none', minWidth: '90px' }}>
                            <Card sx={{ background: 'none' }}>
                                <FavoriteBorderOutlinedIcon fontSize="large" color="primary" />
                                <Typography component="div" variant="body1" fontSize="15px" color={theme.palette.dark.main}>
                                    Favoritos
                                </Typography>
                            </Card>
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid
                    item
                    textAlign="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        pageNavigation('/cart');
                    }}
                >
                    <AnimateButton>
                        <Button sx={{ boxShadow: 'none', minWidth: '90px' }}>
                            <Badge color="secondary" badgeContent={cartItems.length > 0 && user ? cartItems.length : 0}>
                                <Card sx={{ background: 'none' }}>
                                    <ShoppingCartOutlinedIcon fontSize="large" color="primary" />
                                    <Typography component="div" variant="body1" fontSize="15px" color={theme.palette.dark.main}>
                                        Carrito
                                    </Typography>
                                </Card>
                            </Badge>
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item textAlign="center" component={Link} to="/account">
                    <AnimateButton>
                        <Button sx={{ boxShadow: 'none', minWidth: '90px' }}>
                            <Card sx={{ background: 'none' }}>
                                <AccountCircleOutlinedIcon fontSize="large" color="primary" />
                                <Typography component="div" variant="body1" fontSize="15px" color={theme.palette.dark.main}>
                                    Cuenta
                                </Typography>
                            </Card>
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WebNavbar;
