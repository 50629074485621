import { SHOW_HIDE_CART, ADD_TO_CART, REMOVE_CART_ITEM, UPDATE_CART_ITEM, EMPTY_CART } from './actionType';

const cartReducer = (state, action) => {
    console.log('Acción:', action.type);
    switch (action.type) {
        case SHOW_HIDE_CART: {
            return {
                ...state,
                showCart: !state.showCart
            };
        }
        case ADD_TO_CART: {
            return {
                ...state,
                cartItems: [...state.cartItems, action.payload]
            };
        }
        case REMOVE_CART_ITEM: {
            const updatedItems = state.cartItems.filter((item) => item !== action.payload.id);
            console.log('Array actualizado:', updatedItems);
            return {
                ...state,
                cartItems: updatedItems
            };
        }
        case UPDATE_CART_ITEM: {
            return {
                ...state,
                cartItems: state.cartItems.map((el) => (el?.id === action.payload?.id ? action.payload : el))
            };
        }
        case EMPTY_CART: {
            return {
                cartItems: []
            };
        }

        default:
            return state;
    }
};

export default cartReducer;
