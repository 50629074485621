import { useReducer, useState } from 'react';
import CartContext from './CartContext';
import CartReducer from 'store/cartReducer';
import { SHOW_HIDE_CART, ADD_TO_CART, REMOVE_CART_ITEM, UPDATE_CART_ITEM, EMPTY_CART } from 'store/actionType';

const CartState = ({ children }) => {
    const localCartItems = JSON.parse(localStorage.getItem('TGiFCart'));
    const [cartItems, setCartItems] = useState(localCartItems || []);
    const initialState = {
        showCart: false,
        cartItems: localCartItems || []
    };

    const [state, dispatch] = useReducer(CartReducer, initialState);

    const addToCart = (item) => {
        setCartItems([...cartItems, item]);
        dispatch({ type: ADD_TO_CART, payload: item });
    };
    const showHideCart = () => {
        dispatch({ type: SHOW_HIDE_CART });
    };
    const removeCart = (id) => {
        console.log('id a eliminar:', id.id);
        console.log(cartItems);
        const newCartItems = cartItems.filter((item) => Number(item) !== Number(id.id));
        console.log('Nuevo array de ítems:', newCartItems);
        setCartItems(newCartItems);
        dispatch({ type: REMOVE_CART_ITEM, payload: { id: id.id } });
    };
    const updateCart = (item) => {
        dispatch({ type: UPDATE_CART_ITEM, payload: item });
    };
    const emptyCart = () => {
        dispatch({ type: EMPTY_CART });
    };

    return (
        <CartContext.Provider
            value={{
                showCart: state.showCart,
                cartItems: state.cartItems,
                addToCart,
                showHideCart,
                removeCart,
                updateCart,
                emptyCart
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
export default CartState;
