import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'ui-component/Loadable';
import FrontLayout from 'layout/FrontLayout';
import UnderTabLIsting from 'views/pages/HomePage/UnderTabLIsting';
import TagItemListing from 'views/pages/TagItems/TagItemList';

// Home routing
const Home = Loadable(lazy(() => import('views')));
const Explore = Loadable(lazy(() => import('views/pages/Explore')));
const FoodItemDetails = Loadable(lazy(() => import('views/pages/FoodItemDetails')));

// Home routing
const QrScanner = Loadable(lazy(() => import('views/components/QrScanner')));
// PAGES
const StoreList = Loadable(lazy(() => import('views/pages/StoreList')));
const WishList = Loadable(lazy(() => import('views/pages/WishList')));
const Categories = Loadable(lazy(() => import('views/pages/Categories/CategoriesList')));
const Menu = Loadable(lazy(() => import('views/pages/Menu')));
const CategoryItems = Loadable(lazy(() => import('views/pages/CategoryItems/ItemList')));

// FOOTER PAGES ABOUTUS, CONTACTUS, TERMS & CONDITION AND PRIVACY POLICY
const AboutUs = Loadable(lazy(() => import('views/pages/AboutUs/AboutUs')));
const ContactUs = Loadable(lazy(() => import('views/pages/ContactUs/ContactUs')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/PrivacyPolicy/PrivacyPolicy')));
const TermsCondition = Loadable(lazy(() => import('views/pages/TermsCondition/TermsCondition')));

// ACCOUNT ROUTING
const Account = Loadable(lazy(() => import('views/pages/Account/Account')));
const EditProfile = Loadable(lazy(() => import('views/pages/Account/EditProfile')));
const ChangePassword = Loadable(lazy(() => import('views/pages/Account/ChangePassword')));

// ORDER ROUTING
const MyOrder = Loadable(lazy(() => import('views/pages/Order/MyOrder')));

// SKELETON ROUTING
const Skeleton = Loadable(lazy(() => import('views/components/ItemListingSkeleton')));

// ADDRESS ROUTING
const MyAddress = Loadable(lazy(() => import('views/pages/Address/MyAddress')));
const UpdateAddress = Loadable(lazy(() => import('views/pages/Address/UpdateAddress')));

// CHECKOUT ROUTING
const CheckoutPage = Loadable(lazy(() => import('views/pages/Checkout/CheckoutPage')));

// ALERT NOTIFICATION ROUTING
const AlertNotifaction = Loadable(lazy(() => import('views/pages/Notification/Notification')));

// CART ROUTING
const ItemCart = Loadable(lazy(() => import('views/pages/Cart/Cart')));

// ERROR NOT FOUND ROUTING
const PageNotFound = Loadable(lazy(() => import('views/auth/maintenance/Error')));

// AUTH ROUTING
const Login = Loadable(lazy(() => import('views/auth/authentication/Login')));
const Register = Loadable(lazy(() => import('views/auth/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('views/auth/authentication/ForgotPassword')));
const CodeVerification = Loadable(lazy(() => import('views/auth/authentication/CodeVerification')));
const ResetPassword = Loadable(lazy(() => import('views/auth/authentication/ResetPassword')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <FrontLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/',
            element: <Home />,
            children: [
                {
                    path: '/',
                    element: <UnderTabLIsting />
                },
                {
                    path: '/near-me/recommended',
                    element: <UnderTabLIsting />
                },
                {
                    path: '/near-me/dishes-of-the-week',
                    element: <UnderTabLIsting />
                },
                {
                    path: '/near-me/best-selling',
                    element: <UnderTabLIsting />
                }
            ]
        },
        {
            path: '/menu/:slug/:storeID/:tableID',
            element: <Menu />
        },
        {
            path: '/tagItems/:tagItemID/:tagItemSlug',
            element: <TagItemListing />
        },
        {
            path: '/qr-scanner',
            element: (
                <AuthGuard>
                    <QrScanner />
                </AuthGuard>
            )
        },
        {
            path: '/account',
            element: (
                <AuthGuard>
                    <Account pageBehaviour="View" />
                </AuthGuard>
            )
        },
        {
            path: '/about-us',
            element: (
                // <AuthGuard>
                <AboutUs pageBehaviour="View" />
                // </AuthGuard>
            )
        },
        {
            path: '/contact-us',
            element: (
                // <AuthGuard>
                <ContactUs pageBehaviour="View" />
                // </AuthGuard>
            )
        },
        {
            path: '/privacy-policy',
            element: (
                // <AuthGuard>
                <PrivacyPolicy pageBehaviour="View" />
                // </AuthGuard>
            )
        },
        {
            path: '/terms-conditions',
            element: (
                // <AuthGuard>
                <TermsCondition pageBehaviour="View" />
                // </AuthGuard>
            )
        },

        {
            path: '/edit-profile',
            element: (
                <AuthGuard>
                    <EditProfile pageBehaviour="Edit" />
                </AuthGuard>
            )
        },
        {
            path: '/orders',
            element: (
                <AuthGuard>
                    <MyOrder />
                </AuthGuard>
            )
        },
        {
            path: '/skeleton',
            element: (
                <AuthGuard>
                    <Skeleton />
                </AuthGuard>
            )
        },
        {
            path: '/address',
            element: (
                <AuthGuard>
                    <MyAddress pageBehaviour="View" />
                </AuthGuard>
            )
        },
        {
            path: '/add-address',
            element: (
                <AuthGuard>
                    <UpdateAddress pageBehaviour="Add" />
                </AuthGuard>
            )
        },
        {
            path: '/edit-address',
            element: (
                <AuthGuard>
                    <UpdateAddress pageBehaviour="Edit" />
                </AuthGuard>
            )
        },
        {
            path: '/checkout-page',
            element: (
                <AuthGuard>
                    <CheckoutPage pageBehaviour="View" />
                </AuthGuard>
            )
        },
        {
            path: '/explore',
            element: <Explore />
        },
        {
            path: '/categories',
            element: <Categories pageBehaviour="View" />
        },
        {
            path: '/menu',
            element: <Menu pageBehaviour="View" />
        },
        {
            path: '/categories/:categoryID/:categorySlug',
            element: <CategoryItems pageBehaviour="View" />
        },
        {
            path: '/food-item-details/:itemID/:itemSlug',
            element: <FoodItemDetails />
        },
        {
            path: '/store-list',
            element: <StoreList />
        },
        {
            path: '/wish-list',
            element: (
                <AuthGuard>
                    <WishList />
                </AuthGuard>
            )
        },
        {
            path: '/change-password',
            element: (
                <AuthGuard>
                    <ChangePassword />
                </AuthGuard>
            )
        },
        {
            path: '/notification',
            element: (
                <AuthGuard>
                    <AlertNotifaction />
                </AuthGuard>
            )
        },
        {
            path: '/cart',
            element: (
                <AuthGuard>
                    <ItemCart />
                </AuthGuard>
            )
        },
        {
            path: '/login',
            element: (
                <GuestGuard>
                    <Login />
                </GuestGuard>
            )
        },
        {
            path: '/register',
            element: (
                <GuestGuard>
                    <Register />
                </GuestGuard>
            )
        },
        {
            path: '/forgot',
            element: (
                <GuestGuard>
                    <ForgotPassword />
                </GuestGuard>
            )
        },
        {
            path: '/code-verify',
            element: (
                <GuestGuard>
                    <CodeVerification />
                </GuestGuard>
            )
        },
        {
            path: '/reset-password',
            element: (
                <GuestGuard>
                    <ResetPassword />
                </GuestGuard>
            )
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]
};

export default MainRoutes;
