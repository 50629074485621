import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// MUI
import { useTheme } from '@mui/styles';
import { Box, Container, Grid, Button, Typography } from '@mui/material';

// MUI ICONS
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PushPinIcon from '@mui/icons-material/PushPin';

// ASSETS
import { gridSpacing } from 'store/constant';
import LogoWhite from '../../../assets/img/TGIF-LOGO-WHITE.png';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import WebNavbar from './WebNavbar';
import SearchSection from './SearchSection';
import { useSelector } from 'react-redux';

// ===================== || WEB HEADER || ======================
const WebHeader = ({ handleStoreModel, selectedStoreName, currentAddress, setAllowLocation }) => {
    const theme = useTheme();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const { siteSettingData } = useSelector((state) => state.siteSetting);
    const storeName = localStorage.getItem('selectedStoreName');

    // handle Search bar
    const handleSearchBar = () => {
        setShowSearchBar((state) => !state);
    };

    // handle store location
    const handleStoreLocation = () => {
        handleStoreModel();
    };

    return (
        <Box sx={{ background: theme.palette.gradient.secondary }} className="web-header">
            <Container>
                <Grid item container className="topbar" justifyContent="space-between" alignItems="center" sx={{ pt: 1, pb: 3 }}>
                    <Grid item className="logo" component={Link} to="/">
                        <LazyLoadImage src={LogoWhite} alt="Logo" width="150px" />
                    </Grid>
                    <Grid item>
                        <Grid item container justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        sx={{
                                            boxShadow: 'none',
                                            color: '#ffffff',
                                            backgroundColor: 'rgba(255,255,255,0.1)',
                                            minWidth: '150px',
                                            justifyContent: 'flex-start',
                                            gap: '5px'
                                        }}
                                        onClick={() => {
                                            setAllowLocation(true);
                                        }}
                                    >
                                        <LocationOnIcon />
                                        <Grid item textAlign="left">
                                            <Typography fontSize="14px" component="div" lineHeight="1.2">
                                                Ubicación
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    color: '#f4f4f4',
                                                    maxWidth: 180,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '1',
                                                    WebkitBoxOrient: 'vertical'
                                                }}
                                                component="div"
                                                lineHeight="1.2"
                                            >
                                                {currentAddress}
                                            </Typography>
                                        </Grid>
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        sx={{
                                            boxShadow: 'none',
                                            color: '#ffffff',
                                            backgroundColor: 'rgba(255,255,255,0.1)',
                                            minWidth: '130px',
                                            justifyContent: 'flex-start',
                                            gap: '5px'
                                        }}
                                        onClick={handleStoreLocation}
                                    >
                                        <PushPinIcon />
                                        <Grid item textAlign="left">
                                            <Typography fontSize="14px" component="div" lineHeight="1.2">
                                                Restaurante
                                            </Typography>
                                            <Typography sx={{ fontSize: '10px', color: '#f4f4f4' }} component="div" lineHeight="1.2">
                                                {!selectedStoreName ? storeName : selectedStoreName}
                                            </Typography>
                                        </Grid>
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PhoneIcon />}
                                        sx={{ boxShadow: 'none', fontSize: '16px' }}
                                        href="tel:+919876543210"
                                    >
                                        Llámanos
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* INSERT NAVBAR MENU ITEMS */}
                <Grid maxWidth="900px" margin="auto">
                    <WebNavbar handleSearchBar={handleSearchBar} />
                </Grid>
                {/* Search Option */}
                <Grid container justifyContent="center" sx={{ py: 5 }}>
                    <Grid item className="hero-heading" textAlign="center">
                        <Typography variant="h1" color="#ffffff">
                            {siteSettingData?.banner_setting?.heading
                                ? siteSettingData.banner_setting.heading
                                : 'Descubre la mejor comida y bebida'}
                        </Typography>
                        {siteSettingData?.banner_setting?.description ? (
                            <Typography variant="body" fontSize="17px" color="#ffffff" lineHeight="2">
                                {siteSettingData.banner_setting.description.split('\n').map((item, index) => (
                                    <>
                                        <span>{item}</span>
                                        <br />
                                    </>
                                ))}
                            </Typography>
                        ) : (
                            <Typography variant="body" fontSize="17px" color="#ffffff" lineHeight="2">
                                El mejor sabor de toda Honduras!
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                {showSearchBar && (
                    <Grid container justifyContent="center" sx={{ mb: 3, pb: 5 }}>
                        <Grid item>
                            <SearchSection />
                        </Grid>
                    </Grid>
                )}
            </Container>
        </Box>
    );
};

export default WebHeader;
