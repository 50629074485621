import { globalAction, dispatch } from 'store';
import axios from './axios';

function handleResponse(response) {
    if (response?.msg) dispatch(globalAction('SHOW_TOASTER', { status: response?.status, message: response.msg }));

    // if (response?.type === 'validationError') {
    //     dispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
    // }

    return response;
}

export async function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params
            })
            .then((res) => {
                resolve(res.data);
                // resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export async function post(url, data) {
    try {
        const res = await axios.post(url, data);
        return handleResponse(res.data);
    } catch (error) {
        console.error('Error in post request:', error);
        throw handleResponse(error);
    }
}

export async function put(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, data)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export async function patch(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .patch(url, data)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}

export function deleteData(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${url}/${data}`)
            .then((res) => {
                resolve(handleResponse(res.data));
            })
            .catch((err) => {
                reject(handleResponse(err));
            });
    });
}
