import React, { useContext } from 'react';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Container, Typography, Stack } from '@mui/material';

// Import assets
import LogoWhite from '../../../assets/img/TGIF-LOGO-WHITE.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import CartContext from 'contexts/CartContext';
import { useSelector } from 'react-redux';

// =================== || FOOTER || ============================= //
const Footer = ({ footerStoreList }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { emptyCart } = useContext(CartContext);
    const { siteSettingData } = useSelector((state) => state.siteSetting);

    const handleStoreChange = (store) => {
        localStorage.setItem('selectedStoreName', store.name);
        localStorage.setItem('selectedStoreID', store.id);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        emptyCart();
        navigate('/');
    };

    return (
        <Box sx={{ background: theme.palette.secondary.main }}>
            <Container sx={{ py: 4 }}>
                <Grid item container justifyContent="space-between" className="custom-footer-content" spacing={1}>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                        <img src={LogoWhite} alt="Footer Logo" width="80%" />
                        {siteSettingData?.site_setting?.footer_description && (
                            <Typography component="div" variant="body" lineHeight="1.5" sx={{ mb: 2 }}>
                                {siteSettingData.site_setting.footer_description.split('\n').map((item, index) => (
                                    <>
                                        <span>{item}</span>
                                        <br />
                                    </>
                                ))}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3}>
                        <Typography variant="h4" color="#ffffff" lineHeight="1.5">
                            Restaurantes
                        </Typography>
                        <Typography variant="body" lineHeight="2" sx={{ mb: 2 }}>
                            {footerStoreList &&
                                footerStoreList.map((store, key) => (
                                    <Stack key={key}>
                                        <Typography
                                            variant="body"
                                            lineHeight="2"
                                            sx={{ display: 'block', color: '#616161 !important', cursor: 'pointer' }}
                                            onClick={() => {
                                                handleStoreChange(store);
                                            }}
                                        >
                                            {store?.name}
                                        </Typography>
                                    </Stack>
                                ))}
                            {footerStoreList && footerStoreList.length > 5 && (
                                <Stack>
                                    <Typography
                                        variant="body"
                                        lineHeight="2"
                                        sx={{ display: 'block', color: '#616161 !important', cursor: 'pointer', fontWeight: 700 }}
                                        onClick={() => {
                                            navigate('/store-list');
                                        }}
                                    >
                                        View All
                                    </Typography>
                                </Stack>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={3} color="#ffffff">
                        <Typography variant="h4" color="#ffffff" sx={{ mb: 2 }}>
                            SOCIAL MEDIA
                        </Typography>
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                            {siteSettingData?.social_setting?.facebook && (
                                <a href={siteSettingData?.social_setting?.facebook} target="blank">
                                    <FacebookIcon sx={{ color: '#f7f5f5 !important' }} />
                                </a>
                            )}
                            {siteSettingData?.social_setting?.twitter && (
                                <a href={siteSettingData?.social_setting?.twitter} target="blank">
                                    <TwitterIcon sx={{ color: '#f7f5f5 !important' }} />
                                </a>
                            )}
                            {siteSettingData?.social_setting?.instagram && (
                                <a href={siteSettingData?.social_setting?.instagram} target="blank">
                                    <InstagramIcon sx={{ color: '#f7f5f5 !important' }} />
                                </a>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
