import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Grid, useMediaQuery } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useLocation, useParams } from 'react-router-dom';
import ItemCard from './ItemCard';
import ItemListingSkeleton from './ItemListingSkeleton';

// ========================= || FOOD ITEM LISTING || ==================
const FoodItemListing = ({ listingItems, handelItemListDetail, setUpdatedWishlist, isItemLoading = false }) => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const { categoryID } = useParams();
    const { state } = useLocation();
    const [isLoading, setLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1300);
    }, [state, categoryID]);

    return (
        <Grid container justifyContent={matchDownSm ? 'center' : ''} spacing={matchDownSm ? 2 : 3} sx={{ my: 2, mb: 4 }}>
            {isLoading ? (
                <>
                    <ItemListingSkeleton />
                </>
            ) : (
                listingItems &&
                listingItems.map((item, key) => (
                    <ItemCard item={item} handelItemListDetail={handelItemListDetail} setUpdatedWishlist={setUpdatedWishlist} key={key} />
                ))
            )}
            {isItemLoading && <ItemListingSkeleton />}
        </Grid>
    );
};

export default FoodItemListing;
