import React from 'react';
import { API_URL } from 'config';
import { Card, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// ================= || TAG ITEMS SLIDER || =====================
const TagItemsSlider = ({ slider }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Card
            className="Slider-Item"
            sx={{
                position: 'relative',
                height: '250px',
                cursor: 'pointer'
            }}
            onClick={() => {
                navigate(`/food-item-details/${slider?.id}/${slider?.slug}`);
            }}
        >
            <LazyLoadImage
                src={`${API_URL}${slider?.image}`}
                alt={slider?.name}
                style={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'cover',
                    overflow: 'hidden'
                }}
            />
            <Stack
                className="content"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                sx={{
                    position: 'absolute',
                    bottom: '0px',
                    left: 0,
                    width: '100%',
                    minHeight: '80px',
                    backgroundColor: 'rgba(0,0,0,0.6)'
                }}
            >
                <Typography variant="body" color="#ffffff" lineHeight={1.5}>
                    <b>{slider?.name.toUpperCase()}</b> <br />
                    {slider?.category_name}
                </Typography>
            </Stack>
        </Card>
    );
};

export default TagItemsSlider;
