import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

// MUI
import { Card, Grid, Stack, Typography, useMediaQuery, Container, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

// Assets
import { get, post } from 'utils/axiosRequests';
import { API_URL } from 'config';
import useAuth from 'hooks/useAuth';
import AllCategorySlider from 'views/components/slider/AllCategorySlider';
import FoodItemListing from 'views/components/FoodItemListing';

// ======================= || Food Item || ====================== //
const TagItemList = ({ name }) => {
    const theme = useTheme();
    const { user } = useAuth();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const { tagItemID } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedStoreID = localStorage.getItem('selectedStoreID');
    const [orderLimit, setOrderLimit] = useState(6);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStop, setIsLoadingStop] = useState(false);
    const [updatedWishlist, setUpdatedWishlist] = useState(false);

    const [listingItems, setListingItems] = useState(undefined);
    useEffect(() => {
        setListingItems([]);
        setPage(1);
    }, [tagItemID]);

    useEffect(() => {
        if (tagItemID && selectedStoreID) {
            get(
                user
                    ? `${API_URL}/api/tagsItem?store_id=${selectedStoreID}&tags=${tagItemID}&user_id=${user?.id}&page=${page}&record=${orderLimit}`
                    : `${API_URL}/api/tagsItem?store_id=${selectedStoreID}&tags=${tagItemID}&page=${page}&record=${orderLimit}`
            )
                .then((res) => {
                    setListingItems((prev) => [...prev, ...res.data]);
                    // setWishlistUpdated(false);
                    setIsLoading(false);
                    if (res.data.length === 0) {
                        setIsLoadingStop(true);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setIsLoading(false);
                });
        }
    }, [tagItemID, selectedStoreID, page]);

    const handleInfiniteScroll = async (e) => {
        // const scrollHeight = e.target.documentElement.scrollHeight;
        // // const innerHeight = window.innerHeight;
        // const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
        try {
            if (scrolledToBottom && !isLoadingStop) {
                setIsLoading(true);
                setPage(page + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /* useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        return () => window.removeEventListener('scroll', handleInfiniteScroll);
    }, [page, tagItemID]); */

    const handelItemListDetail = (id, slug) => {
        navigate(`/food-item-details/${id}/${slug}`);
    };

    const breadcrumbs = [
        <Stack direction="row" alignItems="center" spacing={0.5} component={Link} to="/">
            <HomeOutlinedIcon color="primary" sx={{ mt: -0.5 }} />
            <Typography color="primary" sx={{ fontWeight: 500 }}>
                Inicio
            </Typography>
        </Stack>,
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                {state?.heading}
            </Typography>
        </Stack>
    ];

    return (
        <Card sx={{ p: 1, boxShadow: 1 }}>
            <Container sx={{ py: 1.5 }}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Container>
            <Container>
                <Grid container>
                    <Grid item xs={12} sx={matchDownSm ? { mt: 1 } : { mt: 2 }}>
                        <AllCategorySlider />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3">{state?.heading}</Typography>
                    </Grid>
                </Grid>
                <FoodItemListing
                    listingItems={listingItems}
                    handelItemListDetail={handelItemListDetail}
                    setUpdatedWishlist={setUpdatedWishlist}
                    isItemLoading={isLoading}
                />
            </Container>
        </Card>
    );
};

export default TagItemList;
