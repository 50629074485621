import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { API_URL } from 'config';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CategoryIcon from '@mui/icons-material/Category';
import StarIcon from '@mui/icons-material/Star';
import useAuth from 'hooks/useAuth';
import { post } from 'utils/axiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';

const ItemCard = ({ item, handelItemListDetail, setUpdatedWishlist }) => {
    const theme = useTheme();
    const { user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [wishlistUpdated, setWishlistUpdated] = useState(false);
    const selectedStoreID = localStorage.getItem('selectedStoreID');

    useEffect(() => {
        if (item?.wishlist_status === 'like') {
            setWishlistUpdated(true);
        } else {
            setWishlistUpdated(false);
        }
    }, [item]);

    const addToWishlist = (id) => {
        if (user) {
            const wishListData = { user_id: user.id, item_id: id, store_id: selectedStoreID };
            post(`${API_URL}/api/wishlistCreate?store_id=${selectedStoreID}&user_id=${user.id}`, wishListData).then((res) => {
                if (res.status === 'success') {
                    if (wishlistUpdated) {
                        setWishlistUpdated(false);
                    } else {
                        setWishlistUpdated(true);
                    }
                    setUpdatedWishlist(true);
                }
            });
        } else {
            navigate('/login', { state: { previousUrl: location.pathname } });
        }
    };

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} key={item?.id} style={{ maxWidth: '500px' }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                columnSpacing={1.6}
                sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px',
                    borderRadius: 3,
                    backgroundColor: '#ffffff',
                    p: 1,
                    pl: 0,
                    pr: 1.2
                }}
            >
                <Grid
                    item
                    xs={4}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        handelItemListDetail(item.id, item?.slug);
                    }}
                >
                    <Card className="img-card">
                        <img alt={item?.name} src={`${API_URL}${item?.image}`} width="100%" style={{ height: 100, objectFit: 'contain' }} />
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Typography
                            variant="h4"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                handelItemListDetail(item.id, item?.slug);
                            }}
                        >
                            {item?.name}
                        </Typography>
                        <Grid item onClick={() => addToWishlist(item?.id)} sx={{ cursor: 'pointer', zIndex: '1' }}>
                            {wishlistUpdated ? (
                                <Favorite sx={{ color: theme.palette.primary.main }} />
                            ) : (
                                <FavoriteBorder sx={{ color: theme.palette.primary.main }} />
                            )}
                        </Grid>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            handelItemListDetail(item.id, item?.slug);
                        }}
                    >
                        <Stack direction="row" alignItems="center">
                            <CategoryIcon sx={{ fontSize: '17px' }} color="primary" />
                            <Typography variant="body" fontSize="14px" color={theme.palette.grey[700]}>
                                &nbsp;{item?.category_name}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                            <StarIcon sx={{ fontSize: '18px', color: '#FF9529' }} />
                            <Typography variant="body" fontSize="15px" lineHeight="2">
                                {item?.total_count_rating && item?.total_count_hit_rating
                                    ? (item?.total_count_rating / item?.total_count_hit_rating).toFixed(1)
                                    : 0}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid
                                    item
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        handelItemListDetail(item.id, item?.slug);
                                    }}
                                >
                                    <Grid container>
                                        <Grid item sx={{ pt: 0.5 }}>
                                            <AccountBalanceWalletIcon sx={{ fontSize: '20px', color: theme.palette.primary.main }} />{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h6"
                                                lineHeight="1"
                                                color="primary"
                                                sx={{ fontSize: '15px', fontWeight: 'bold', mt: 1 }}
                                            >
                                                {' '}
                                                {item?.discount !== 0 ? (
                                                    <>
                                                        <span style={{ textDecoration: 'line-through', fontWeight: 'normal' }}>
                                                            LPS {item?.price}
                                                        </span>{' '}
                                                        LPS {item?.discounted_price.toFixed(2)}
                                                    </>
                                                ) : (
                                                    `LPS ${item?.price.toFixed(2)}`
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ItemCard;
