import React from 'react';
import { useTheme } from '@mui/styles';
import { Grid, Stack, useMediaQuery, Skeleton } from '@mui/material';
import ImagePlaceholder from 'ui-component/cards/Skeleton/ImagePlaceholder';

const ItemListingSkeleton = () => (
    <>
        <Grid item xs={12} sm={12} md={6} lg={4} key={1} style={{ maxWidth: '500px' }}>
            <Stack direction="row" spacing={2} height="100px" alignItems="center">
                <ImagePlaceholder height="95px" width="30%" sx={{ borderRadius: 2 }} />
                <Stack width="70%" height="100%" spacing={0.5}>
                    <Skeleton width="100%" height="40px" />
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="100%" height="5px" />
                    <Skeleton width="100%" height="25px" />
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} key={2} style={{ maxWidth: '500px' }}>
            <Stack direction="row" spacing={2} height="100px" alignItems="center">
                <ImagePlaceholder height="95px" width="30%" sx={{ borderRadius: 2 }} />
                <Stack width="70%" height="100%" spacing={0.5}>
                    <Skeleton width="100%" height="40px" />
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="100%" height="5px" />
                    <Skeleton width="100%" height="25px" />
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} key={3} style={{ maxWidth: '500px' }}>
            <Stack direction="row" spacing={2} height="100px" alignItems="center">
                <ImagePlaceholder height="95px" width="30%" sx={{ borderRadius: 2 }} />
                <Stack width="70%" height="100%" spacing={0.5}>
                    <Skeleton width="100%" height="40px" />
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="100%" height="5px" />
                    <Skeleton width="100%" height="25px" />
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} key={4} style={{ maxWidth: '500px' }}>
            <Stack direction="row" spacing={2} height="100px" alignItems="center">
                <ImagePlaceholder height="95px" width="30%" sx={{ borderRadius: 2 }} />
                <Stack width="70%" height="100%" spacing={0.5}>
                    <Skeleton width="100%" height="40px" />
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="100%" height="5px" />
                    <Skeleton width="100%" height="25px" />
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} key={5} style={{ maxWidth: '500px' }}>
            <Stack direction="row" spacing={2} height="100px" alignItems="center">
                <ImagePlaceholder height="95px" width="30%" sx={{ borderRadius: 2 }} />
                <Stack width="70%" height="100%" spacing={0.5}>
                    <Skeleton width="100%" height="40px" />
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="100%" height="5px" />
                    <Skeleton width="100%" height="25px" />
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} key={6} style={{ maxWidth: '500px' }}>
            <Stack direction="row" spacing={2} height="100px" alignItems="center">
                <ImagePlaceholder height="95px" width="30%" sx={{ borderRadius: 2 }} />
                <Stack width="70%" height="100%" spacing={0.5}>
                    <Skeleton width="100%" height="40px" />
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="100%" height="5px" />
                    <Skeleton width="100%" height="25px" />
                </Stack>
            </Stack>
        </Grid>
    </>
);

export default ItemListingSkeleton;
