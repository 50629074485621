import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// MUI
import { useTheme } from '@mui/styles';
import {
    Container,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    FormControlLabel,
    Radio,
    Menu,
    FormControl,
    Badge,
    RadioGroup
} from '@mui/material';

// MUI ICONS
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ListIcon from '@mui/icons-material/List';

// ASSETS

// project imports
import { get } from 'utils/axiosRequests';
import { API_URL } from 'config';
import CartContext from 'contexts/CartContext';
import useAuth from 'hooks/useAuth';

const menuStyles = {
    backgroundColor: 'primary',
    borderRadius: '10px',
    // padding: '2px 5px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
};

// ===================== || Mobile Menu Buttons || ======================
const MobileMenuButtons = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { cartItems } = useContext(CartContext);
    const { user } = useAuth();
    const [storeID, setStoreID] = useState(undefined);
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // Handle Menu List Show hide
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = (event) => {
        setAnchorEl(event?.currentTarget);
    };
    const handleMenuClose = (event) => {
        setAnchorEl(null);
    };

    const [allMenuItems, setAllMenuItems] = useState(undefined);

    useEffect(() => {
        setStoreID(localStorage.getItem('selectedStoreID'));
    }, []);

    useEffect(() => {
        if (storeID) {
            get(`${API_URL}/api/categories?store_Id=${storeID}`).then((res) => {
                setAllMenuItems(res.data);
            });
        }
    }, [storeID]);

    const handelMenuListDetail = (id, slug_category, name) => {
        navigate(`/categories/${id}/${slug_category}`, { state: name });
    };
    return (
        <>
            {location.pathname !== '/login' && location.pathname !== '/register' && (
                <Container>
                    <Grid item xs={12} textAlign="center" sx={{ mb: 1 }}>
                        <AnimateButton>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#141414', color: '#ffffff', borderRadius: 12.5 }}
                                onClick={handleMenuOpen}
                            >
                                <ListIcon /> &nbsp;Menú
                            </Button>
                        </AnimateButton>
                        <Menu
                            id="all-menu-list"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            variant="selectedMenu"
                            sx={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center'
                            }}
                        >
                            {/* all menu listing Start */}
                            <FormControl sx={{ p: 1 }}>
                                <RadioGroup
                                    aria-labelledby="sorting-radio-buttons-group-label"
                                    defaultValue="relevance"
                                    name="radio-buttons-group"
                                    onChange={handleMenuClose}
                                >
                                    {allMenuItems &&
                                        allMenuItems.map((menu, key) => (
                                            <FormControlLabel
                                                key={key}
                                                value={menu?.name}
                                                control={<Radio />}
                                                onClick={() => {
                                                    handelMenuListDetail(menu?.id, menu?.slug_category, menu?.name);
                                                }}
                                                label={
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="space-between" spacing={5}>
                                                            <Grid item>{menu?.name}</Grid>
                                                            <Grid item>{menu?.count}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Menu>
                    </Grid>
                </Container>
            )}
            <Container style={menuStyles} sx={{ background: theme.palette.primary.main }}>
                <Grid item container className="mobilemenu" justifyContent="space-around" alignItems="center" spacing={0}>
                    <Tabs
                        className="footer-menu-tab-list"
                        value={value}
                        textColor="inherit"
                        indicatorColor="secondary"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#ffffff'
                            }
                        }}
                        onChange={handleTabChange}
                        aria-label="icon label tabs"
                    >
                        <Tab
                            key={1}
                            className="footer-menu-tab-button"
                            icon={<HomeOutlinedIcon />}
                            label={<Typography color="#f5f5f5">Inicio</Typography>}
                            component={Link}
                            to="/"
                        />
                        <Tab
                            key={2}
                            className="footer-menu-tab-button"
                            icon={<NotificationsNoneOutlinedIcon />}
                            label={<Typography color="#f5f5f5">Alerta</Typography>}
                            component={Link}
                            to="/notification"
                        />
                        <Tab
                            key={3}
                            className="footer-menu-tab-button"
                            icon={<SearchOutlinedIcon sx={{ position: 'relative' }} />}
                            label={<Typography color="#f5f5f5">Explorar</Typography>}
                            component={Link}
                            to="/explore"
                        />
                        <Tab
                            key={4}
                            className="footer-menu-tab-button"
                            icon={
                                <Badge color="secondary" badgeContent={cartItems.length > 0 && user ? cartItems.length : 0}>
                                    <ShoppingCartOutlinedIcon />
                                </Badge>
                            }
                            label={<Typography color="#f5f5f5">Carrito</Typography>}
                            component={Link}
                            to="/cart"
                        />
                        <Tab
                            key={5}
                            className="footer-menu-tab-button"
                            icon={<AccountCircleOutlinedIcon />}
                            label={<Typography color="#f5f5f5">Cuenta</Typography>}
                            component={Link}
                            to="/account"
                        />
                    </Tabs>
                </Grid>
            </Container>
        </>
    );
};

export default MobileMenuButtons;
