// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const SET_LOGIN = 'SET_LOGIN';
export const SHOW_HIDE_CART = 'SHOW_HIDE_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const EMPTY_CART = 'EMPTY_CART';
