import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

// ASSETS
import backgroundTexture from '../../assets/img/design/texture-bg.jpg';
import selfieImage from '../../assets/img/design/selfie.jpg';
import stripe1 from '../../assets/img/design/stripe-01.png';
import stripe2 from '../../assets/img/design/stripe-02.png';
import Logo from '../../assets/img/LOGO-TGIF.png';
import { get } from 'utils/axiosRequests';
import CartContext from 'contexts/CartContext';
import Loading from 'ui-component/Loading';

const modelDesign = {
    backgroundImage: `url(${backgroundTexture})`,
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
};
const selectFieldStyle = {
    width: '250px',
    padding: '10px',
    outline: 'none',
    fontSize: '16px',
    textAlign: 'center'
};

// ============ || MODEL FOR SELECT STORE || =============== //
const SelectStoreModel = ({ handleStoreModel }) => {
    const navigate = useNavigate();
    const { emptyCart } = useContext(CartContext);
    const [isLoading, setIsLoading] = useState(true);
    const [allStoreDetails, setAllStoreDetails] = useState([]);
    useEffect(() => {
        get('/api/storeList')
            .then((res) => {
                setAllStoreDetails(res.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const [selectedStoreName, setSelectedStoreName] = useState(undefined);
    const [selectedStoreID, setSelectedStoreID] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const handleStoreChange = (e) => {
        setSelectedStoreName(e.currentTarget.options[e.currentTarget.selectedIndex].text);
        setSelectedStoreID(e.target.value);
        setErrorMessage('');
    };
    const handleSubmit = () => {
        if (selectedStoreName) {
            handleStoreModel();
            localStorage.setItem('selectedStoreName', selectedStoreName);
            localStorage.setItem('selectedStoreID', selectedStoreID);
            localStorage.setItem('selectedStore', 'true');
            emptyCart();
            navigate('/');
        } else {
            setErrorMessage('Please select any store');
        }
    };

    return (
        <Box className="store-select-model">
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100vh', zIndex: '999999999' }}
                className="store-model-box"
            >
                <Grid item xs={12} sm={8} md={5} lg={3} style={modelDesign}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} textAlign="right">
                            <img src={stripe1} alt="Stripe Top" />
                        </Grid>
                        {isLoading ? (
                            <Stack direction="row" justifyContent="center" sx={{ py: 7 }}>
                                <Loading />
                            </Stack>
                        ) : (
                            <>
                                <Grid item xs={12} sx={{ my: 3 }}>
                                    <img src={Logo} alt="Friday Selfie" width="50%" />
                                </Grid>
                                <Grid item xs={12}>
                                    <img src={selfieImage} alt="Friday Selfie" width="60%" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" lineHeight="3">
                                        Elegir Restaurante
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sx={{ mb: 3 }}>
                                    <Grid container sx={{ zIndex: '1' }}>
                                        <Grid item xs={12}>
                                            <select
                                                placeholder="Store Name"
                                                id="storeName"
                                                name="storeName"
                                                value={selectedStoreName?.value}
                                                onChange={handleStoreChange}
                                                style={selectFieldStyle}
                                            >
                                                <option value=" ">Seleccionar</option>
                                                {allStoreDetails &&
                                                    allStoreDetails.map((store, key) => (
                                                        <option key={key} value={store?.id} label={store?.name}>
                                                            {store?.name}
                                                        </option>
                                                    ))}
                                            </select>
                                            <Typography lineHeight={2} color="error">
                                                {errorMessage}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: 3 }}>
                                            <AnimateButton>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    sx={{ borderRadius: 0 }}
                                                    endIcon={<ArrowRightAltOutlinedIcon />}
                                                    onClick={handleSubmit}
                                                >
                                                    Ingresar
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} textAlign="left" alignSelf="end" style={{ marginBottom: '-5px' }}>
                            <img src={stripe2} alt="Stripe Bottom" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SelectStoreModel;
