import { useTheme } from '@mui/styles';
import React from 'react';
import SplashImage from '../../assets/img/FRIDAY.gif';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SplashScreen = () => {
    const theme = useTheme();
    return (
        <div className="splash-screen">
            <LazyLoadImage src={SplashImage} alt="Splash Screen" height="50%" />
        </div>
    );
};

export default SplashScreen;
