import React, { memo, useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Card, Typography, Stack, Box, useMediaQuery } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Accets
import { get } from 'utils/axiosRequests';
import { API_URL } from 'config';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper';

import ImagePlaceholder from 'ui-component/cards/Skeleton/ImagePlaceholder';
import { useNavigate } from 'react-router-dom';

// ================= || ALL CATEGORY SLIDER || ================== //

const AllCategorySlider = () => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const [sliders, setSliders] = useState([]);
    const navigate = useNavigate();
    const selectedStoreID = localStorage.getItem('selectedStoreID');

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (selectedStoreID) {
            get(`${API_URL}/api/categories?store_Id=${selectedStoreID}`).then((res) => {
                setSliders(res.data);
            });
        }
    }, [selectedStoreID]);

    useEffect(() => {
        if (sliders.length > 0) {
            setLoading(false);
        }
    }, [sliders]);

    const handleBanner = (id, slug, name) => {
        navigate(`/categories/${id}/${slug}`, { state: name });
    };

    return (
        <>
            {sliders && (
                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    freeMode="true"
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay, FreeMode]}
                    className="HomePageMainSlider"
                    breakpoints={{
                        0: {
                            slidesPerView: 3
                        },
                        575: {
                            slidesPerView: 4
                        },
                        900: {
                            slidesPerView: 6
                        }
                    }}
                >
                    {sliders.map((slider, key) => (
                        <SwiperSlide key={key} style={{ justifyContent: 'center', width: '100%' }}>
                            {isLoading ? (
                                <ImagePlaceholder width="100%" height={100} sx={{ borderRadius: 3 }} />
                            ) : (
                                <Card
                                    className="Slider-Item"
                                    sx={{
                                        height: matchDownSm ? 90 : 110,
                                        cursor: 'pointer',
                                        boxShadow: 2,
                                        textAlign: 'center',
                                        position: 'relative'
                                    }}
                                    onClick={() => {
                                        handleBanner(slider?.id, slider?.slug_category, slider?.name);
                                    }}
                                >
                                    <LazyLoadImage
                                        src={`${API_URL}${slider?.image}`}
                                        alt={slider?.name}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden' }}
                                    />
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            position: 'absolute',
                                            top: '75%',
                                            left: 0,
                                            width: '100%',
                                            height: '25%',
                                            backgroundColor: 'rgba(0,0,0,0.6)'
                                        }}
                                    >
                                        <Typography variant="body" color="#ffffff">
                                            <b>{slider?.name.toUpperCase()}</b>
                                        </Typography>
                                    </Stack>
                                </Card>
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </>
    );
};

export default memo(AllCategorySlider);
