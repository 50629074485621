import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useNavigate, Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// MUI
import { Box, Container, Grid, Button, Stack } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PhoneIcon from '@mui/icons-material/Phone';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

// ASSETS
import Logo from '../../../assets/img/TGIF-LOGO-STYLED.jpg';
import { get, post } from 'utils/axiosRequests';
import { API_URL } from 'config';
import useAuth from 'hooks/useAuth';

const mobileHeaderStyle = {
    boxShadow: '2',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: '99',
    background: '#ffffff'
};

// =================== || MOBILE HEADER || ========================= //
const MobileHeader = ({ handleStoreModel }) => {
    const theme = useTheme();
    const { user } = useAuth();
    const navigate = useNavigate();
    const handleStoreLocation = () => {
        handleStoreModel();
    };

    const selectedStoreID = localStorage.getItem('selectedStoreID');
    const [orderList, setOrderList] = useState([]);
    useEffect(() => {
        if (user) {
            get(`${API_URL}/api/orders?store_id=${selectedStoreID}`).then((res) => {
                setOrderList(res.data?.orders_list);
            });
        }
    }, [selectedStoreID]);

    const storeID = localStorage.getItem('selectedStoreID');
    const tableID = localStorage.getItem('selectedTableID');

    const handleCallWaiter = () => {
        if (user) {
            if (tableID && storeID) {
                post(`${API_URL}/api/call-waiter`, {
                    table_id: tableID,
                    user_id: user?.id,
                    restaurant_id: storeID
                }).then((res) => {
                    if (res.status === 'success') {
                        // setItemUpdated(true);
                        // setIsDisable(false);
                    }
                });
            } else {
                navigate('/qr-scanner');
            }
        } else {
            navigate('/login');
        }
    };

    return (
        <Box sx={mobileHeaderStyle}>
            <Container>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ py: 1.2 }}>
                    <Grid item className="mobile-logo">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {window.location.pathname !== '/' && window.location.pathname !== '/' && (
                                <Button
                                    size="small"
                                    // startIcon={<ArrowBackIosNewOutlinedIcon />}
                                    sx={{ borderShadow: 'none', borderRadius: 0, minWidth: 20, width: 20 }}
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBackIosNewOutlinedIcon />
                                </Button>
                            )}
                            <Stack component={Link} to="/">
                                <LazyLoadImage src={Logo} alt="Logo" height={40} width="auto" />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        {window.location.pathname === '/' || window.location.pathname === '/near-me' ? (
                            <>
                                <AnimateButton>
                                    <Button color="secondary" sx={{ boxShadow: 'none', minWidth: 0 }} component={Link} to="/qr-scanner">
                                        <QrCodeScannerIcon />
                                        {/* &nbsp;Location */}
                                    </Button>
                                </AnimateButton>
                                <AnimateButton>
                                    <Button color="primary" sx={{ boxShadow: 'none', minWidth: 0 }} onClick={handleStoreLocation}>
                                        <StoreMallDirectoryIcon />
                                        {/* &nbsp;Location */}
                                    </Button>
                                </AnimateButton>
                                <AnimateButton>
                                    <Button sx={{ boxShadow: 'none', minWidth: 0 }} onClick={handleCallWaiter}>
                                        <PhoneIcon />
                                    </Button>
                                </AnimateButton>
                            </>
                        ) : (
                            <>
                                <Stack direction="row" spacing={1}>
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            startIcon={<PhoneIcon />}
                                            sx={{ boxShadow: 'none', borderRadius: 12.5 }}
                                            onClick={handleCallWaiter}
                                        >
                                            Anfitrión
                                        </Button>
                                    </AnimateButton>
                                </Stack>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default MobileHeader;
