import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
// third-party
// import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, SET_USER } from 'store/actionType';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

import { globalAction } from 'store';
import CartContext from './CartContext';
// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { cartItems, removeCart } = useContext(CartContext);
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [loggedin, setLoggedin] = useState(false);
    const storeDispatch = useDispatch();
    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken) {
                    setSession(serviceToken);
                    const response = await axios.get('/api/fetch');
                    if (response.data.status === 'success') {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: response.data.user
                            }
                        });
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, [profileUpdated, loggedin]);

    const login = async (email, password) => {
        try {
            let response = await axios.post('/api/login', { email, password });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSession(response.token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
                setLoggedin(true);
                navigate(location?.state?.previousUrl ? location?.state?.previousUrl : '/');
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: error.msg }));
        }
    };

    const logout = () => {
        cartItems.map((items) => removeCart(items?.id));
        setSession(null);
        dispatch({ type: LOGOUT });
        localStorage.removeItem('selectedTableID');
    };

    const sendOtp = async (email) => {
        await axios.post('/api/forgetPassword', email);
    };

    const loginWithOTP = async (email, otp) => {
        try {
            let response = await axios.post('/api/users/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSession(response.token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const verifyOTP = async (email, otp) => {
        try {
            let response = await axios.post('/api/verify-otp', { email, otp });
            response = response.data;
            if (response?.msg) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.msg }));
            }
            if (response.status === 'success') {
                setSession(response.token);
                navigate('/reset-password', { replace: true, state: { otp, email } });
                dispatch({
                    type: SET_USER,
                    payload: {
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(
                globalAction('SHOW_TOASTER', { status: 'error', message: error?.msg || 'Something wents wrong! Please try again!' })
            );
        }
    };

    const register = async (values) => {
        try {
            let response = await axios.post('/api/registration', values);
            response = response.data;
            if (response?.msg) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response?.status, message: response?.msg }));
            }
            if (response.status === 'success' || true) {
                navigate('/login');
            }
        } catch (error) {
            if (error?.errors) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: Object.values(error?.errors)[0].toString() }));
            } else {
                storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
            }
        }
    };

    const resetPassword = async (values) => {
        try {
            let response = await axios.post('/api/resetPassword', values);
            response = response.data;
            if (response?.msg) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.msg }));
            }

            if (response.status === ('success' || true)) {
                navigate('/login');
            }
        } catch (error) {
            storeDispatch(
                globalAction('SHOW_TOASTER', { status: 'error', message: error?.msg || 'Something wents wrong! Please try again!' })
            );
        }
    };

    const updateProfile = async (values) => {
        try {
            let response = await axios.post('/api/updateProfile', values);
            response = response.data;
            setProfileUpdated(true);
            if (response?.msg) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response?.status, message: response?.msg }));
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, sendOtp, verifyOTP, register, resetPassword, updateProfile, loginWithOTP }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
