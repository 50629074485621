import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { get } from 'utils/axiosRequests';
import axios from 'axios';

// assets
import WebHeader from './Header/WebHeader';
import MobileHeader from './Header/MobileHeader';
import Footer from './Footer/Footer';
import MobileMenuButtons from './Footer/MobileMenuButtons';
import SplashScreen from './SplashScreen';
import SelectStoreModel from './SelectStoreModel';
import useAuth from 'hooks/useAuth';
import CartContext from 'contexts/CartContext';
import { API_URL } from 'config';
import { getSiteSettings } from 'store/slices/siteSetting';
import { useDispatch } from 'react-redux';

const mobileMenuStyle = {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    zIndex: '999'
};

// ==============================|| FRONT LAYOUT ||============================== //

const FrontLayout = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { storeID } = useParams();
    // const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
    // const matchDownBelowSmall = useMediaQuery(theme.breakpoints.down('sm'));
    // const matchSmallDevice = useMediaQuery('(max-width:480px)');
    const { addToCart, removeCart, cartItems } = useContext(CartContext);
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('serviceToken')}` }
    };

    const [allCartList, setAllCartList] = useState([]);
    const selectedStoreID = localStorage.getItem('selectedStoreID');
    useEffect(() => {
        if (user && selectedStoreID) {
            axios.get(`${API_URL}/api/cartShow?store_id=${selectedStoreID}`, config).then((res) => {
                setAllCartList(res.data.data);
            });
        }
    }, [user, selectedStoreID]);

    useEffect(() => {
        if (user && allCartList && allCartList.length > 0) {
            allCartList.map((CartItem) => removeCart({ id: CartItem?.id }));
            allCartList.map((CartItem) => addToCart(CartItem));
        } else if (allCartList && allCartList.length > 0) {
            allCartList.map((CartItem) => removeCart({ id: CartItem?.id }));
        }
    }, [allCartList, user]);

    const [allStoreDetails, setAllStoreDetails] = useState([]);
    const [footerStoreList, setFooterStoreList] = useState([]);
    useEffect(() => {
        get('/api/storeList').then((res) => {
            setAllStoreDetails(res.data);
        });
    }, []);

    useEffect(() => {
        setFooterStoreList(allStoreDetails.splice(0, 6));
    }, [allStoreDetails]);

    const [storeSelected, setStoreSelected] = useState(false);
    useEffect(() => {
        const isStoreSelected = localStorage.getItem('selectedStore');
        if (isStoreSelected === 'true') {
            setStoreSelected(true);
        } else {
            setStoreSelected(false);
        }
    }, [storeSelected]);

    useEffect(() => {
        dispatch(getSiteSettings());
    }, [dispatch]);

    // ===================================================
    const [storeDistance, setStoreDistance] = useState([]);
    const [allowLocation, setAllowLocation] = useState(false);
    const [selectedStoreName, setSelectedStoreName] = useState('');

    // GER CURRENT LOCATION
    const [currentLocation, setCurrentrLocation] = useState(undefined);
    const showPosition = (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        setCurrentrLocation({ latitude: lat, longitude: long });
    };
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }, [allowLocation]);

    // GET CURRENT LOCATION ADDRESS
    const [currentAddress, setCurrentrAddress] = useState('Allow your location');
    useEffect(() => {
        if (currentLocation) {
            axios
                .post(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.latitude},${currentLocation.longitude}&key=AIzaSyCIMI3ItgVDhGVAIXOwRgulncPIvRExWNs`
                )
                .then((res) => {
                    if (res.data.status === 'OK') {
                        setCurrentrAddress(res.data.results[0].formatted_address);
                        localStorage.setItem('currentAddress', currentAddress);
                    }
                });
        }
    }, [currentLocation]);

    useEffect(() => {
        if (currentLocation && allStoreDetails) {
            allStoreDetails.map((store) => {
                const proxyurl = 'https://cors-anywhere.herokuapp.com/';
                const url = `https://maps.googleapis.com/maps/api/distancematrix/json?units=miles&origins=${currentLocation.latitude},${currentLocation.longitude}&destinations=${store?.direction?.lat},${store?.direction?.long}&key=AIzaSyCIMI3ItgVDhGVAIXOwRgulncPIvRExWNs`;
                return axios.get(proxyurl + url).then((res) => {
                    if (res.status === 'OK') {
                        const distance = res.rows[0].elements[0].distance.value;
                        const duration = res.rows[0].elements[0].duration.value;
                        setStoreDistance([
                            ...storeDistance,
                            { storeId: store.id, storeName: store.name, storeDistance: distance, storeDuration: duration }
                        ]);
                    }
                });
            });
        }
    }, [currentLocation, allStoreDetails]);

    useEffect(() => {
        if (storeDistance.length > 0) {
            const minValue = Math.min(...storeDistance.map((item, index) => item.cost));
            const index = storeDistance.findIndex((d) => d.cost === minValue);
            localStorage.setItem('selectedStoreID', storeDistance[index].storeId);
            localStorage.setItem('selectedStoreName', storeDistance[index].storeName);
            setSelectedStoreName(storeDistance[index].storeName);
            setStoreSelected(true);
        }
    }, [storeDistance]);

    // ===================================================
    const [splashScreen, setSplashScreen] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setSplashScreen(false);
        }, 2000);
    });

    // HANDLE STORE SELECTED FROM MODAL
    const handleStoreModel = () => {
        if (!storeSelected) {
            setStoreSelected(true);
            navigate('/');
        } else {
            setStoreSelected(false);
            localStorage.setItem('selectedStore', 'false');
        }
    };

    const footer = useMemo(() => <Footer footerStoreList={footerStoreList} />, [footerStoreList]);

    return (
        <Box>
            {splashScreen && window.location.pathname === '/' ? (
                <SplashScreen />
            ) : (
                <>
                    {!storeSelected && !storeID && (
                        <SelectStoreModel handleStoreModel={handleStoreModel} allStoreDetails={allStoreDetails} />
                    )}
                    <Grid className="custom-header">
                        {matchDownSm ? (
                            <MobileHeader handleStoreModel={handleStoreModel} />
                        ) : (
                            <WebHeader
                                handleStoreModel={handleStoreModel}
                                selectedStoreName={selectedStoreName}
                                currentAddress={currentAddress}
                                setAllowLocation={setAllowLocation}
                            />
                        )}
                    </Grid>
                    <Grid className="body-content" sx={matchDownSm ? { pt: 6.8 } : {}}>
                        <Outlet />
                    </Grid>
                    <Grid className="custom-footer">{footer}</Grid>
                    {matchDownSm && (
                        <Grid className="mobile-menu-buttons" style={mobileMenuStyle} sx={{ p: 0.7, pb: 0 }}>
                            <MobileMenuButtons />
                        </Grid>
                    )}
                </>
            )}
        </Box>
    );
};

export default FrontLayout;
