import { useTheme } from '@mui/styles';
import React, { memo, useState, useEffect } from 'react';

// MATERIAL UI
import { Stack, Card, Grid, Typography } from '@mui/material';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Accets
import { get } from 'utils/axiosRequests';
import { API_URL } from 'config';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/pagination/pagination.min.css';

// import required modules
import { FreeMode, Pagination, Autoplay } from 'swiper';

import ImagePlaceholder from 'ui-component/cards/Skeleton/ImagePlaceholder';
import useAuth from 'hooks/useAuth';
import TagItemsSlider from 'views/components/slider/TagItemsSlider';

// ================= || OUR SPECIAL || ================== //

const UnderTabListing = ({ title, apiUrl, tab }) => {
    const theme = useTheme();
    const { user } = useAuth();
    const accordionName = tab?.tags;

    const [isLoading, setLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const selectedStoreID = localStorage.getItem('selectedStoreID');
    const [tabList, setTabList] = useState(undefined);
    useEffect(() => {
        if (selectedStoreID) {
            get(
                user
                    ? `${API_URL}/api/tagsItem?store_id=${selectedStoreID}&tags=${tab?.tags}&user_id=${user?.id}`
                    : `${API_URL}/api/tagsItem?store_id=${selectedStoreID}&tags=${tab?.tags}`
            ).then((res) => {
                setTabList(res.data);
            });
        }
    }, [selectedStoreID]);

    return (
        <>
            {tabList && tabList.length > 0 ? (
                <>
                    <Grid item container spacing={2} justifyContent="space-between" alignItems="center">
                        <Grid item sx={{ mb: 1 }}>
                            <Typography variant="body">{title}</Typography>
                        </Grid>
                    </Grid>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        freeMode="true"
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        modules={[Autoplay, FreeMode, Pagination]}
                        className="OurSpecialSlider"
                        breakpoints={{
                            0: {
                                slidesPerView: 1
                            },
                            360: {
                                slidesPerView: 2
                            },
                            575: {
                                slidesPerView: 3
                            },
                            900: {
                                slidesPerView: 5
                            }
                        }}
                    >
                        {tabList.map((slider, key) => (
                            <SwiperSlide key={key}>
                                {isLoading ? <ImagePlaceholder height="250px" /> : <TagItemsSlider slider={slider} />}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            ) : (
                <Stack>
                    <Card sx={{ p: 2, py: 1.5, backgroundColor: theme.palette.warning.main }}>
                        <Typography variant="subtitle1" color="InfoText">
                            No Data Found
                        </Typography>
                    </Card>
                </Stack>
            )}
        </>
    );
};

export default memo(UnderTabListing);
